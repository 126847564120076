<template>
  <div class="section-wrap py-16 d-flex align-center">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="text-h5 text-center white--text font-weight-bold mb-4 mb-sm-12"
        >
          헬퍼를 쓰는 이유?
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="4"
          class="px-4 py-4"
        >
          <div class="reason-card">
            <p class="bg-card text-center text-h6">
              비용이 저렴하고
            </p>
            <p class="pa-0  text-center">
              업계 최저 비용으로<br>간병일을 하실 수 있습니다.
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="px-4 py-4"
        >
          <div class="reason-card">
            <p class="bg-hand text-center text-h6">
              이용방법이 쉽고
            </p>
            <p class="pa-0  text-center">
              여사님, 선생님들이<br>사용하시기 편하게 만들었습니다.
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="px-4 py-4"
        >
          <div class="reason-card">
            <p class="bg-pin text-center text-h6">
              일감이 많습니다.
            </p>
            <p class="pa-0  text-center">
              여러 대형 병원들과<br>협약되어 있어 일감이 많습니다.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.section-wrap {
  background-color: #F9F9F9;
  min-height: 518px;
  background: url('./assets/bg_reason.jpg') center center no-repeat #F9F9F9;
  background-size: cover;
}
.reason-card {
  background: #FFFFFF;
  border: 2px solid #10AFFF;
  box-sizing: border-box;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.bg-card {
  background: url('./assets/icon_credit_circle.png') center top no-repeat;
  background-size: 86px;
  padding-top: 110px !important;
}
.bg-hand {
  background: url('./assets/icon_hand_circle.png') center top no-repeat;
  background-size: 86px;
  padding-top: 110px !important;
}
.bg-pin {
  background: url('./assets/icon_pin_circle.png') center top no-repeat;
  background-size: 86px;
  padding-top: 110px !important;
}
</style>
