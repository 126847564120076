<template>
  <div
    class="banner-wrap d-flex align-center"
    :style="`height: ${bannerHeight}px`"
  >
    <v-container>
      <v-row
        no-gutters
        justify="center"
        align="center"
      >
        <v-col
          cols="12"
          class="white--text text-center text-h5 text-sm-h4 text-md-h3"
          :class="{ 'under375px-title': under375px}"
        >
          전국 어디든, <span class="font-weight-bold">내 근처 일자리 찾기!</span>
        </v-col>
        <v-col
          cols="12"
          class="text-center white--text text-caption text-sm-subtitle-2 text-md-h6"
          :class="(under375px ? 'pt-0' : 'pt-3')"
        >
          앱에서 {{ under375px ? '' : '일자리 찾기로 ' }} 환자 정보를 확인하고 일자리 지원해보세요!
        </v-col>
        <v-col
          cols="8"
          sm="6"
          md="4"
          class="mt-0 mt-sm-6 mt-md-8"
        >
          <!-- height="50" -->
          <v-btn
            block
            depressed
            color="#007AFF"
            :height="(under375px ? '40' : '50')"
            class="white--text"
            :class="(under375px ? 'mt-3' : 'mt-4')"
            style="width: 100%;"
            @click="$bus.$emit('showContactCenterDialog', 'withKakao')"
          >
            <p
              class="font-weight-bold ma-0 btn-search"
            >
              지금바로 일자리 찾기
            </p>
          </v-btn>
        </v-col>
      </v-row>
      <!-- v-show="!$vuetify.breakpoint.xsOnly" -->
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col
          cols="4"
          sm="3"
          md="2"
          class="mt-2 pr-1"
        >
          <router-link
            :to="$store.getters['App/appInfo'].androidAppUrl.replace('https:', '')"
            target="_blank"
          >
            <v-img
              :src="require('../layouts/default/assets/app-link-google-play.png')"
              width="100%"
            />
          </router-link>
        </v-col>
        <v-col
          cols="4"
          sm="3"
          md="2"
          class="mt-2 pl-1"
        >
          <router-link
            :to="$store.getters['App/appInfo'].iosAppUrl.replace('https:', '')"
            target="_blank"
          >
            <v-img
              :src="require('../layouts/default/assets/app-link-app-store.png')"
              width="100%"
            />
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    isMobile: false,
    under375px: false,
    bannerHeight: 200
  }),

  beforeDestroy() {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },

  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  methods: {
    onResize() {
      // console.log(window.innerWidth)
      const breakPoint = this.$vuetify.breakpoint.thresholds.xs
      const minHeight = 200
      let height = window.innerWidth - breakPoint
      if (height < 0) {
        height = minHeight
      } else {
        height += minHeight
        if (height >= 530) {
          height = 530
        }
      }
      if (window.innerWidth < 375) {
        console.log('375px 이하')
        this.under375px = true
      } else {
        this.under375px = false
      }
      this.bannerHeight = height
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-wrap {
  height:100%;
  min-height: 130px;
  max-height: 530px;
  background: url('./assets/bg.jpg') center center no-repeat;
  background-size: cover;
  // background-color: linear-gradient(90deg, #DADBE1 0%, rgba(218, 219, 225, 0.81) 44.24%, rgba(218, 219, 225, 0) 98.77%);
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dadbe1+0,d6d4d7+51,d0ced4+100 */
  background-color: #dadbe1; /* Old browsers */
  background-color: -moz-linear-gradient(top,  #dadbe1 0%, #d6d4d7 51%, #d0ced4 100%); /* FF3.6-15 */
  background-color: -webkit-linear-gradient(top,  #dadbe1 0%,#d6d4d7 51%,#d0ced4 100%); /* Chrome10-25,Safari5.1-6 */
  background-color: linear-gradient(to bottom,  #dadbe1 0%,#d6d4d7 51%,#d0ced4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dadbe1', endColorstr='#d0ced4',GradientType=0 ); /* IE6-9 */

}
.btn-search {
  background: url('./assets/icon-search.png') center left no-repeat;
  background-size: 20px;
  padding-left: 24px;
}
.subtitle {
  color: #4188c8;
}
.under375px-title {
  font-size: 1.3rem !important;
}
</style>
