<template>
  <div>
    <Banner />
    <Section1 />
    <Section2 />
    <Section3 />
  </div>
</template>

<script>
import Banner from './Banner'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'

export default {
  components: {
    Banner,
    Section1,
    Section2,
    Section3
  }
}
</script>

<style lang="scss" scoped>
</style>
