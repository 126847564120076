<template>
  <div class="section-wrap py-16">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="text-h5 text-center font-weight-bold mb-4 mb-sm-12"
        >
          이렇게 헬퍼를 이용하고 계십니다.
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pa-0">
      <v-row v-if="$vuetify.breakpoint.mdAndUp">
        <v-col
          v-for="item, k in items"
          :key="`review-card-item-${k}`"
          cols="12"
          sm="6"
        >
          <Section3CardComponent
            v-model="items[k]"
          />
        </v-col>
      </v-row>
      <v-row
        v-else
        no-gutters
        justify="space-between"
        align="center"
      >
        <v-col cols="auto">
          <v-btn
            icon
            @click="prev"
          >
            <v-icon>
              arrow_back_ios_new
            </v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-container>
            <v-row
              align="top"
              justify="center"
            >
              <v-col
                cols="12"
                md="6"
              >
                <Section3CardComponent
                  v-model="items[firstItemCursor]"
                />
              </v-col>
              <v-col
                v-show="$vuetify.breakpoint.mdAndUp"
                cols="12"
                md="6"
              >
                <Section3CardComponent
                  v-model="items[secondItemCursor]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="auto">
          <v-btn
            icon
            @click="next"
          >
            <v-icon>
              arrow_forward_ios
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Section3CardComponent from './Section3Card'

export default {
  components: {
    Section3CardComponent
  },
  data() {
    return {
      firstItemCursor: 0,
      secondItemCursor: 0,
      items: [
        {
          gender: 'female',
          name: '김미소',
          area: '서울',
          message: '다른 협회처럼 보험도 가입되네요. 굳이 다른 협회 가입해서 비싼 회비 안줘도 되니 좋네요'
        },
        {
          gender: 'male',
          name: '성경오',
          area: '경기',
          message: '환자분 상태를 자세히 알려주니까 간병하기가 편하네요.'
        },
        {
          gender: 'male',
          name: '박경민',
          area: '전주',
          message: '간병비도 직접 보호자한테 받고 따로 수수료가 없어서 좋네요.'
        },
        {
          gender: 'female',
          name: '송혜교',
          area: '광주',
          message: '내가 직접 환자분을 선택할 수 있어서 좋네요. 다른 협회는 강제로 보내는 곳도 많은데..'
        },
        {
          gender: 'female',
          name: '오만정',
          area: '부산',
          message: '생각보다 일감이 많습니다.'
        },
        {
          gender: 'male',
          name: '공병진',
          area: '청주',
          message: '잘모르면 고객센터로 전화해보세요. 친절합니다.'
        }
      ]
    }
  },
  methods: {
    prev() {
      const lastCursor = this.items.length - 1
      const step = this.$vuetify.breakpoint.mdAndUp ? 2 : 1
      this.firstItemCursor -= step

      if (this.firstItemCursor < 0) {
        this.firstItemCursor += (lastCursor + 1)
      }
      this.secondItemCursor = this.firstItemCursor + 1
      if (lastCursor < this.secondItemCursor) {
        this.secondItemCursor -= (lastCursor + 1)
      }
    },
    next() {
      const lastCursor = this.items.length - 1
      const step = this.$vuetify.breakpoint.mdAndUp ? 2 : 1

      this.firstItemCursor += step
      if (lastCursor < this.firstItemCursor) {
        this.firstItemCursor -= (lastCursor + 1)
      }
      this.secondItemCursor = this.firstItemCursor + 1
      if (lastCursor < this.secondItemCursor) {
        this.secondItemCursor -= (lastCursor + 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-wrap {
  background-color: #F9F9F9;
}
.review-card {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 16px;
  height: 100%;
}
.label-area {
  display: inline-block;
  border: 0.443964px solid #007AFF;
  box-sizing: border-box;
  border-radius: 22.1982px;
  padding-left: 16px;
  padding-right: 16px;
  color: #007AFF;
  font-weight: 500;
}
</style>
