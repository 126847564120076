<template>
  <div class="review-card">
    <v-container class="py-8 px-12">
      <v-row
        align="center"
        justify="center"
        justify-sm="start"
      >
        <v-col cols="auto">
          <img
            :src="require(`./assets/illust_${(value.gender === 'male') ? 'man' : 'woman'}.png`)"
            style="max-width: 70px;"
          >
        </v-col>
        <v-col
          cols="auto"
          class="pt-0 pt-sm-2"
        >
          <v-container class="pa-0">
            <v-row
              no-gutters
              justify="center"
              justify-sm="start"
            >
              <v-col
                cols="auto"
                class="py-2"
              >
                <span class="font-weight-bold text-body-1">{{ value.name | nameConv }}</span> 간병사님
              </v-col>
              <v-col
                cols="auto"
                class="px-3 py-2"
              >
                <span class="label-area">{{ value.area }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        style="margin-top: 12px; border-top: 0.443964px solid #E5E5E5;"
      >
        <v-col class="pt-4">
          {{ value.message }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import nameConv from '../caregiver/model/filters'

export default {
  filters: {
    nameConv
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          gender: '',
          name: '',
          area: '',
          message: ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-wrap {
  background-color: #F9F9F9;
}
.review-card {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 16px;
  height: 100%;
}
.label-area {
  display: inline-block;
  border: 0.443964px solid #007AFF;
  box-sizing: border-box;
  border-radius: 22.1982px;
  padding-left: 16px;
  padding-right: 16px;
  color: #007AFF;
  font-weight: 500;
}
</style>
